<template>
    <div class="chat-comp">
      <div class="content-top-chat" ref="box">
        <div class="single-message" v-for="message, index in messageList">
          <div class="message-info"><span class="user-name">{{message.userID}}</span><span class="message-time">{{getMessageTime(message)}}</span></div>
          <div class="message-content"><span v-for="item, index in message.renderContent"><span v-if="item.name === 'text'" :key="index">{{item.content }}</span><img class="message-icon" v-else-if="item.name === 'img'" :src="item.src" :key="index" /></span></div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="content-bottom">
        <div class="content-bottom-feel">
          <Poptip placement="top" width="400" trigger="click" >
            <div class="emojis" slot="content">
              <div class="emoji" v-for="emoji in emojiName" :key="emoji" @click="chooseEmoji(emoji)"><img :src="emojiUrl + emojiMap[emoji]" /></div>
            </div>

            <span class="icon-button" >
              <svg-icon class="emoji-icon" icon-name="emoji"></svg-icon>
            </span>
          </Poptip>
        </div>
        <div class="content-bottom-input">
          <input class="input" ref="input" type="text" v-model="inputMsg" placeholder="输入信息" @keyup.enter="handleSendMsg" :disabled="isLiveEnded" required="required" /><span class="send-button" @click="handleSendMsg">发送</span>
        </div>
      </div>
    </div>
</template>

<script>
import itemCard from '@/components/common/item-card';
// import tim from '@/components/mixin/tim';
import { emojiMap, emojiName, emojiUrl } from '@/utils/emojiMap';
import { LIVE_STAGE } from 'constants/room';
import { mapState } from 'vuex';
export default {
  name: 'compChat',
  // mixins: [tim],
  data() {
    return {
      inputMsg: '',
      rec: '',
      popoverVisible: false,
      emojiMap,
      emojiName,
      emojiUrl,
    };
  },
  components: {
    itemCard,
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
      userInfo: 'userInfo',
      userId: state => state.userInfo.userId,
      sdkAppId: state => state.appInfo.sdkAppId,
      userSig: state => state.appInfo.userSig,
    }),
    isLiveEnded() {
      return this.liveStage === LIVE_STAGE.ENDED;
    },
    userData() {
      return {
        sdkAppId: this.sdkAppId,
        userSig: this.userSig,
        userId: this.userId,
      };
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler(val) {
        if (val.sdkAppId && val.userSig && val.userId) {
          this.initTim();
          console.log('初始化 TIM');
        }
      },
    },
    // 发出一条新消息，自动到最底部
    messageList() {
      console.log('messageList change');
      this.$nextTick(() => {
        const msg =  this.$refs.box;
        msg.scrollTop = msg.scrollHeight;
      });
    },
    // 直播结束之后解散群聊
    liveStage(val) {
      if (val === LIVE_STAGE.ENDED) {
        // this.destroyChat();
      }
    },
    memberList: {
      immediate: true,
      handler(val) {
        this.$store.commit('UPDATE_MEMBER_LIST', val);
      },
    },
  },
  methods: {
    // 获取用户昵称
    getUserNick({ nick, userID }) {
      return userID;
    },
    getMessageTime({ time }) {
      let hour = new Date(time * 1000).getHours();
      let minute = new Date(time * 1000).getMinutes();
      hour = hour >= 10 ? hour.toString() : `0${hour}`;
      minute = minute >= 10 ? minute.toString() : `0${minute}`;
      return `${hour}:${minute}`;
    },
    // 发送消息
    handleSendReViewMsg(msg, user) {
      // if (this.inputMsg === '' || /^\s+$/gi.test(this.inputMsg)) {
      //   return;
      // }
      console.log(user, 'useruseruseruser');
      this.sendSysMessage(msg, user);
      this.popoverVisible = false;
    },
    // 发送消息
    handleSendMsg() {
      if (this.inputMsg === '' || /^\s+$/gi.test(this.inputMsg)) {
        return;
      }
      this.sendMessage(this.inputMsg);
      this.inputMsg = '';
      this.popoverVisible = false;
    },
    // 选择表情
    chooseEmoji(item) {
      this.inputMsg += item;
      this.$refs.input.focus();
    },
    // 销毁群聊
    destroyChat() {
      // this.dismissGroup(this.groupID);
      this.logout();
      // 清除对禁言用户ID的记录
      localStorage.removeItem(this.muteUserIdKey);
    },
  },
  mounted() {
    this.$eventBus.$on('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
  },
  beforeDestroy() {
    this.destroyChat();
    this.$eventBus.$off('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
  },
};
</script>

<style lang="stylus" scoped>
.chat-comp
  width 100%
  height calc(100% - 40px)
  display flex
  flex-direction column
.content-top-chat
  flex-grow 1
  width 100%
  height calc(100% - 160px)
  margin 10px 0
  overflow auto
  margin 10px 0
  border-radius 10px
  padding 14px 22px
  color #616472
  font-size 14px
  .out
    color #999
    margin 50% auto
  .single-message
    width 100%
    text-align left
    .message-info
      height 30px
      line-height 30px
      color $grayFontColor
      font-size 14px
      .user-name
        padding-right 12px
    .message-content
      width 80%
      min-width 260px
      background-color rgba(223,223,223,0.05);
      padding 4px 8px
      border-radius 4px
      font-size 12px
      font-weight 500
      word-break break-all
      span
        display inline-block
        vertical-align center
        .message-icon
          width 20px
          height 20px
          vertical-align middle
.divider
  width 100%
  height 2px
  background-color $lineColor

.content-bottom
  width 100%
  padding 12px
  div.content-bottom-feel
    width 100%
    text-align left
    .icon-button
      cursor pointer
      .emoji-icon
        width 24px
        height 24px
      svg
        fill #616472
    .smile-icon
      display inline-block
      width 30px
      height 30px
  div.content-bottom-input
    text-align left
    position relative
    margin 4px auto 0
    .input
      color #616472
      border-radius 5px
      top 0
      right 0
      width 100%
      height 42px
      padding-left 13px
      padding-right 70px
      background-color rgba(0,0,0,0.1)
      border none
      outline none
    .send-button
      height 42px
      line-height 42px
      position absolute
      right 12px
      cursor pointer
      color #616472
      font-size 14px

.emojis
  height 170px
  overflow scroll
  .emoji
    height 40px
    width 40px
    float left
    box-sizing border-box
    cursor pointer
    img
      width 30px
      height 30px
      margin 5px
</style>

