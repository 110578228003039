<template>
  <div class="item-card-container">
    <div class="title-container"><span class="title">{{title}}</span></div>
    <div class="divider"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'itemCard',
  props: {
    title: String,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
.item-card-container
  width 100%
  background-color $themeColor
  border-radius 4px
  .title-container
    width 100%
    height 40px
    line-height 40px
    display flex
    align-items center
    margin-bottom 4px
    .title
      padding-left 20px
      color #616472
      font-weight 500
  .divider
    width 100%
    height 1px
    background-color $lineColor
</style>
